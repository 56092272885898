import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Collapse, Switch, Select, message } from "antd";
import Header from "../components/header";
import "../styles/style.css";

const { Option } = Select;
const { Panel } = Collapse;

function StartNewGroupPremium(props) {
  const [currentUserName, setCurrentUserName] = useState(JSON.parse(localStorage.getItem("currentUser")).u_name);
  const [groupName, setGroupName] = useState("");
  const [groupMotor, setGroupMotor] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupContentType, setGroupContentType] = useState("");
  const [groupMemberType, setGroupMemberType] = useState("");
  const [groupAllowGender, setGroupAllowGender] = useState("");
  const [groupLimitMember, setGroupLimitMember] = useState(10);
  const [groupNonMembers, setGroupNonMembers] = useState(false);
  const [groupStatus, setGroupStatus] = useState(false);
  const [groupInviteOption, setGroupInviteOption] = useState(0);
  const [imgUrl, setImgUrl] = useState("");
  const [files, setFiles] = useState("");

  const onUpload = () => {
    document.getElementById("imgInp").click();
  };

  const selectFile = (e) => {
    const [file] = document.getElementById("imgInp").files;
    setFiles(document.getElementById("imgInp").files);
    if (file) {
      setImgUrl(URL.createObjectURL(file));
    }
  };

  const onSwitchGenderRatio = (checked) => {};

  const onGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const onGroupMotorChange = (e) => {
    setGroupMotor(e.target.value);
  };

  const onGroupDescriptionChange = (e) => {
    setGroupDescription(e.target.value);
  };

  const onGroupContentTypeChange = (e) => {
    if (e.target.checked === true) {
      setGroupContentType(e.target.parentNode.children[1].id);
    }
  };

  const onGroupMemberTypeChange = (e) => {
    if (e.target.checked === true) {
      setGroupMemberType(e.target.parentNode.children[1].id);
    }
  };

  const onGroupLimitMemberChange = (value) => {
    setGroupLimitMember(parseInt(value));
  };

  const onGroupNonMembersChagne = (checked) => {
    setGroupNonMembers(checked);
  };

  const onGroupInviteOptionChange = (checked) => {
    setGroupInviteOption(checked);
  };

  const onGroupStatusChange = (checked) => {
    setGroupStatus(checked);
  };

  const startGroup = () => {
    let formData = new FormData();
    formData.append("upload_stream", files[0]);

    fetch(`${process.env.REACT_APP_URL}/upload_stream`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + `${localStorage.getItem("access_token")}`,
      },
      mode: "cors",
      body: formData,
    }).then((res) => {
      if (res) {
        let response = res.json();
        response.then(function (result) {
          if (result.Status === "Success") {
            let bodyData = {
              g_name: groupName,
              g_motor: groupMotor,
              g_description: groupDescription,
              g_image_url: result.Response,
              g_content_type: groupContentType,
              g_member_type: groupMemberType,
              g_allow_gender: groupAllowGender,
              g_limit_member: groupLimitMember,
              g_invite_option: groupInviteOption,
              g_non_member: groupNonMembers,
              g_status: groupStatus,
            };
            fetch(`${process.env.REACT_APP_URL}/start_group`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + `${localStorage.getItem("access_token")}`,
              },
              mode: "cors",
              body: JSON.stringify(bodyData),
            }).then((res) => {
              if (res) {
                let response = res.json();
                response.then(function (result) {
                  if (result.Status === "Success") {
                    message.success("Group is started successfully!");
                    props.navigate("/my-groups");
                  }
                });
              }
            });
          }
        });
      }
    });
  };

  return (
    <div className="body-content">
      <div className="content">
        <Header />
        <div className="container">
          <h4 className="pt-4 ps-3 mb-3" style={{ fontWeight: "700", width: "100vw", marginTop: "50px" }}>
            Start a New Group
          </h4>
          <div className="content-bottom px-4">
            <p className="mt-2 mb-0 content-1">Group Name:</p>
            <input className="input-box" type="text" value={groupName} onChange={onGroupNameChange} />
            <p className="mt-2 mb-0 content-1">Group Moto: (short slogan)</p>
            <input className="input-box" type="text" value={groupMotor} onChange={onGroupMotorChange} />
            <p className="mt-2 mb-0 content-1">Group Description:</p>
            <textarea className="text-box" value={groupDescription} onChange={onGroupDescriptionChange} />
            <div>
              <p className="mb-0 content-1">Group Image/Photo:</p>
              <div className="mt-2 upload-image-container">
                <img className="upload-image" src={imgUrl} alt="GroupImage" />
                <button className="mt-2 upload-btn" onClick={onUpload}>
                  <span className="material-symbols-outlined">file_upload</span>
                  Upload
                </button>
                <input accept="image/*" type="file" id="imgInp" style={{ display: "none" }} onChange={selectFile} />
              </div>
            </div>
            <p className="mt-5 mb-2 content-1">Allow These Types of Content:</p>
            <div className="row">
              <div className="col-4 mb-2">
                <input className="check-box" type="checkbox" onChange={onGroupContentTypeChange} />
                <label className="ps-2" id="photos">
                  {" "}
                  Photos
                </label>
              </div>
              <div className="col-4 mb-2">
                <input className="check-box" type="checkbox" onChange={onGroupContentTypeChange} />
                <label className="ps-2" id="videos">
                  {" "}
                  Videos
                </label>
              </div>
              <div className="col-4 mb-2">
                <input className="check-box" type="checkbox" onChange={onGroupContentTypeChange} />
                <label className="ps-2" id="status">
                  {" "}
                  Status
                </label>
              </div>
              <div className="col-4 mb-4">
                <input className="check-box" type="checkbox" onChange={onGroupContentTypeChange} />
                <label className="ps-2" id="comments">
                  {" "}
                  Comments
                </label>
              </div>
            </div>
            <p className="mb-2 content-1">Allow These Types of Member:</p>
            <div className="row start-row2">
              <div className="col-4 mb-4">
                <input className="check-box" type="checkbox" onChange={onGroupMemberTypeChange} />
                <label className="ps-2" id="premium">
                  {" "}
                  Premium
                </label>
              </div>
              <div className="col-4 mb-4">
                <input className="check-box" type="checkbox" onChange={onGroupMemberTypeChange} />
                <label className="ps-2" id="free">
                  {" "}
                  Free
                </label>
              </div>
              <div className="col-4 mb-4">
                <input className="check-box" type="checkbox" onChange={onGroupMemberTypeChange} />
                <label className="ps-2" id="PSP">
                  {" "}
                  PSP
                </label>
              </div>
            </div>
            <div className="mt-3 mb-4">
              <p className="mb-2 content-1">Membership Limit:</p>
              <Select
                defaultValue="0"
                style={{
                  width: "100%",
                }}
                onChange={onGroupLimitMemberChange}
              >
                <Option value="0">Unlimited Members</Option>
                <Option value="10">10 members</Option>
                <Option value="25">25 members</Option>
                <Option value="50">50 members</Option>
                <Option value="100">100 members</Option>
                <Option value="200">200 members</Option>
                <Option value="500">500 members</Option>
                <Option value="1000">1000 members</Option>
              </Select>
            </div>
            <div className="mb-3" style={{ position: "relative" }}>
              <p className="mb-2 content-1">Invite only Group:</p>
              <div className="switch-toggle">
                <Switch checked={groupInviteOption} onChange={onGroupInviteOptionChange} />
              </div>
            </div>
            <div className="mb-3" style={{ position: "relative" }}>
              <p className="mb-2 content-1">Allow members to Invite:</p>
              <div className="switch-toggle">
                <Switch onChange={onSwitchGenderRatio} />
              </div>
            </div>
            <div className="mb-3" style={{ position: "relative" }}>
              <p className="mb-2 content-1">Include in suggested groups:</p>
              <div className="switch-toggle">
                <Switch onChange={onGroupStatusChange} />
              </div>
            </div>
            <div className="mb-4" style={{ position: "relative" }}>
              <p className="mb-2 content-1">Non Members can view group:</p>
              <div className="switch-toggle">
                <Switch checked={groupNonMembers} onChange={onGroupNonMembersChagne} />
              </div>
            </div>
            <div className="mb-4" style={{ position: "relative" }}>
              <Collapse bordered={false} expandIconPosition="end">
                <Panel header="Advanced Settings" key="1">
                  <p>This is Advanced Settings.</p>
                </Panel>
              </Collapse>
            </div>
            <div className="my-5 upgrade-section mx-auto" style={{ width: "90%" }} onClick={startGroup}>
              <p className="upgrade-btn">Start Group</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function NavigateMain(props) {
  let navigate = useNavigate();
  return <StartNewGroupPremium {...props} navigate={navigate} />;
}

export default NavigateMain;
