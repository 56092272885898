import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, message } from 'antd';
import SigninWithAllombo from '../pages/sign-in-with-allombo';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import '../styles/style.css';

function SignIn(props) {

    const { switchSignUpTab } = props;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [show, setshow] = useState(false);
    const pass = useRef();

    useEffect(() => {
        // document.getElementsByClassName('openbtn')[0].style.display = 'none';
    }, []);

    const confirm = async () => {
        console.log("forgot password")
        // Modal.warning({
        //   title: 'Reset Password!',
        //   content: 'Please check your email to reset your password.',
        //   okText: 'Confirm',
        //   width: '400px'
        // });
        await fetch(`${process.env.REACT_APP_URL}/forgot_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + `${localStorage.getItem('access_token')}`,
            },
            mode:'cors',
            body: JSON.stringify({
                'email_to': 'devhavrylov@gmail.com',
                'email': 'This is forgot password email',
                'token': localStorage.getItem('access_token')
            })
        })
        .then(res =>{
            if(res){
                let response = res.json();
                response.then(function(result){
                    if(result.Status === 'Success'){
                        console.log(result.Response);
                    }
                })
            }
        })
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('username', name);
        formData.append('password', password);

        if (name == '' || password == '') {
            message.error('You must fill up email and password');
        } else {
            fetch(`${process.env.REACT_APP_URL}/token`, {
                method: 'POST',
                mode:'cors',
                body: formData
            })
            .then(res =>{
                if(res){
                    let response = res.json();
                    response.then(function(result){
                        if(result.Status === 'Success'){
                            localStorage.setItem('access_token', result.access_token);
                            localStorage.setItem('currentUser', JSON.stringify(result.user));
                            message.success('Sign in successfully!');
                            props.navigate("/groups");
                        }
                    })
                }
            })
            .catch(err => {
                message.error(err);
            });
        }

    }

    const onNameChange = (e) => {
        setName(e.target.value);
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const showpassword = () =>{
        setshow(!show)
        pass.current.type = show ? 'password':'text';        
    }
        
    return (
        <div className='body-content'>
            <div className='content'>
                <div className='sign-in-container'>
                    <SigninWithAllombo/>
                    <h4 style={{marginBottom: 'unset'}}> OR </h4>
                    <input 
                        placeholder='Enter User Name'
                        className='sign-in-text'
                        type='text'
                        value={name}
                        onChange={onNameChange}
                    />
                    <div
                        className='pasword text-center'
                        style={{ position: 'relative', width: '100%' }}
                    >
                        <input 
                            ref={pass}
                            placeholder='Enter Password'
                            className='sign-in-text'
                            type='password'
                            value={password}
                            onChange={onPasswordChange}
                        />
                        {
                            show ? 
                                <EyeOutlined onClick={showpassword} /> 
                                : 
                                <EyeInvisibleOutlined onClick={showpassword} />
                        }
                    </div>
                    <div 
                        style={{ 
                            display:'flex', 
                            alignItems: 'center',
                            gap: '10px'
                        }}
                    >
                        <input type='checkbox'></input>
                        <p className='mb-0' id='remember'>Remember Me?</p>
                    </div>
                    <button className='sign-in-btn' onClick={onSubmit} >LogIn</button>
                    
                </div>
                <div className='sign-in-footer'>
                    <p className='text-center'>Not a member? &nbsp;
                        <a style={{color: 'black', fontWeight: 'bolder'}} onClick={ switchSignUpTab }> Sign Up </a>
                    </p>
                    <p id='forgot' className='text-center mb-3' onClick={confirm}> <b>Forgot your password?</b> </p>
                </div>
            </div>
        </div>    
    );
    
}

function NavigateMain(props) {
    let navigate = useNavigate();
    return <SignIn {...props} navigate={navigate} />
}

export default NavigateMain;