import React, {useState, useEffect} from 'react';
import { Checkbox } from 'antd';
import Header from '../components/header';
import '../styles/style.css';

function JoinRequest(props) {

    const [checkList, setCheckList] = useState([]);
    const [checkStatus, setCheckStatus] = useState(1);
    const [requestUserName, setRequestUserName] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/check_join/${localStorage.getItem('currentGroupName')}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + `${localStorage.getItem('access_token')}`,
            },
            mode:'cors',
        })
        .then(res =>{
            if(res){
                let response = res.json();
                response.then(function(result){
                    if(result.Status === 'Success' && result.Response.length > 0){
                        setCheckList(result.Response);
                        setRequestUserName(result.Response[0].user_name)
                    }
                })
            }
        })
    }, []);

    const onStatusChange = (e) => {
        if(e.target.checked === true && e.target.id === 'accept') {
            setCheckStatus(2);
        }else if(e.target.checked === true && e.target.id === 'decline') {
            setCheckStatus(3);
        }
    }

    const decideJoin = () => {
        fetch(`${process.env.REACT_APP_URL}/accept_join/${localStorage.getItem('currentGroupName')}/${requestUserName}?group_name=${localStorage.getItem('currentGroupName')}&user_name=${requestUserName}&status=${checkStatus}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + `${localStorage.getItem('access_token')}`,
            },
            mode:'cors',
        })
        .then(res =>{
            if(res){
                let response = res.json();
                response.then(function(result){
                    console.log(result)
                    if(result.Status === 'Success'){
                    }
                })
            }
        })
    }
    
    return (
        <div className='body-content'>
            <div className='content'>
                <Header/>
                <div className='container'>
                    <h4 className='pt-4 ps-3' style={{fontWeight: '700', width: '100vw', marginTop:'50px'}}>My Group: {localStorage.getItem('currentGroupName')}</h4>
                    <p className='ps-3 group-detail' style={{fontSize: '15px'}}>{localStorage.getItem('curr')} &gt; <strong>Join Requests</strong></p>
                    <div className='row mt-3 mx-0 ps-3 pe-4'>
                        <div className='col-6 text-center p-0'></div>
                        <div className='col-3 text-center p-0'>
                            <p className='group-detail' style={{fontSize: '15px'}}>accept</p>
                        </div>
                        <div className='col-3 text-center p-0'>
                            <p className='group-detail' style={{fontSize: '15px'}}>decline</p>
                        </div>
                        {checkList.length > 0 && 
                            checkList.map(item => {
                                return (
                                    <>
                                        <div className='col-6 text-center p-0 mt-2 pe-2' style={{backgroundColor: 'grey', height: '40px', fontSize: '20px'}}>{item.user_name}</div>
                                        <div className='col-3 text-center p-0 mt-2 pt-2'>
                                            <Checkbox id='accept' onChange={onStatusChange}/>
                                        </div>
                                        <div className='col-3 text-center p-0 mt-2 pt-2'>
                                            <Checkbox id='decline' onChange={onStatusChange}/>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                    <div className='mt-4 mx-0 px-3 text-center'>
                        <button className='find-btn px-3 mb-3 mx-0' style={{width: '80%', height: '40px'}} onClick={decideJoin}>Accept / Decline as Selected</button>
                        <p className='m-0' style={{fontSize: '25px', fontWeight: '500'}}>or</p>
                        <button className='find-btn px-3 mt-3 mx-0' style={{width: '80%', height: '40px'}}>Accept All</button>
                    </div>
                </div>
            </div>
        </div>    
    );
}

export default JoinRequest;