import React, {useState, useEffect} from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Groups from './pages/groups';
import StartNewGroup from './pages/start-new-group';
import StartNewGroupPremium from './pages/start-new-group-premium';
import MyStream from './pages/my-stream';
import MyGroups from './pages/my-groups';
import PopularGoups from './pages/popular-groups';
import FindGroup from './pages/find-group';
import ManageGroups from './pages/manage-groups';
import ManageGroupDetail from './pages/manage-group-detail';
import JoinRequest from './pages/join-request';
import ManageGroupMember from './pages/manage-group-member';
import PostContent from './pages/post-content';
import './styles/style.css';
import Login from './pages/login';

function App() {
  const [invite, setInvite] = useState('');
  const [ws, setWS] = useState();

  useEffect(() => {
    async function getCategoriesMap() { 
      const client_id = Date.now()
      const webSocket = new WebSocket(`${process.env.REACT_APP_WS}/${client_id}`);
      webSocket.onopen = () => {
        webSocket.send('opening a web socket');
      }

      webSocket.onmessage = (message) => {
        // console.log(message);
        setInvite(message);
      };

      setWS(webSocket);
    }
    getCategoriesMap();
  }, []);
  
  return (
    <div className='body'>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/signin" element={<Login/>}/>
            <Route path="/groups" element={<Groups ws={invite}/>}/>
            <Route path="/start-a-group" element={<StartNewGroup/>}/>
            <Route path="/page3" element={<StartNewGroupPremium/>}/>
            <Route path="/my-stream" element={<MyStream/>}/>
            <Route path="/my-groups" element={<MyGroups ws={ws} />}/>
            <Route path="/popular-groups" element={<PopularGoups/>}/>
            <Route path="/find-groups" element={<FindGroup/>}/>
            <Route path="/manage-groups" element={<ManageGroups />}/>
            <Route path="/manage-group-detail" element={<ManageGroupDetail />}/>
            <Route path="/join-request" element={<JoinRequest />}/>
            <Route path="/manage-group-member" element={<ManageGroupMember />}/>
            <Route path="/post-content" element={<PostContent />}/>
            <Route path="/sign-out" element={ <Navigate to='/'/>}/>
          </Routes>
        
      </BrowserRouter>
    </div>
  );
}

export default App;
