import { HeartFilled, UserOutlined } from "@ant-design/icons";
import { GroupStatus } from "../constants/application";

function GroupCard(props) {
  const { data } = props;
  const { imgUrl, name, isInviteOnly, isGroupOwner } = data;
  
  const setCurrentManage = (e) => {
    localStorage.setItem("currentGroupName", name);
  };

  return (
    <>
      <div className="card border-0">
        <div className="card-image">
          <img className="card-img-top" src={imgUrl} alt="" />
          {!!isInviteOnly && (
            <div className="image-overlay text-end">
              <span className="material-symbols-outlined">lock</span>
            </div>
          )}
        </div>

        <div className="bottom-btn-section">
          <div className="bottom-btn-status">
            <HeartFilled />
            72
            <UserOutlined />4
          </div>
          <div className="bottom-btn-action">
            {isGroupOwner === true ? <a href='/manage-group-detail' onClick={setCurrentManage}>{GroupStatus.Manage}</a> : <button>{GroupStatus.Joined}</button>}
          </div>
        </div>
      </div>
      <p className="pt-1" style={{ fontWeight: "500", fontSize: "16px" }}>
        {name}
      </p>
    </>
  );
}

export default GroupCard;
