import React, {useState, useEffect} from 'react';
import { Rate } from 'antd';
import { DownOutlined, HeartFilled, UserOutlined, PictureFilled } from '@ant-design/icons';
import Header from '../components/header';
import '../styles/style.css';
import Sort from '../components/sort';

function PopularGroups() {

    const [visible, setVisible] = useState(false);
    const [titleArr, setTitleArr] = useState(['SiteName', 'flower Browsers', 'MotorCar Fans', 'Rock Climbers']);

    const [modalTitle, setModalTitle] = useState(''); 

    let flag = 0;

    useEffect(() => {
        window.addEventListener("load", () => {
            onResize();
        });
        window.onresize = onResize;
    }, []);

    const onResize = () => {
        let elements = document.getElementsByClassName('start-group');
        for(let i = 0; i < elements.length; i++) {
            let height = (elements[i].clientHeight - elements[i].children[0].clientHeight)/2 + 'px';
            elements[i].style.paddingTop = height;
        }
    }

    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
    };

    const startGroup = (e) => {
        e.target.children[0].click()
    }

    const showDetail = (e) => {
        document.getElementsByClassName('detail-background')[0].style.display = 'block';
        setModalTitle(titleArr[e.target.parentNode.id]);
    }

    const hideDetail = (e) => {
        document.getElementsByClassName('detail-background')[0].style.display = 'none';
    }

    const bodyClick = () => {
        flag = 1;
    }

    const backClick = () => {
        if(flag === 0){
            hideDetail();
        }
        flag = 0;
    }

    return (
        <div className='body-content'>
            <div className='content'>
                <Header/>
                <div className='container'>
                    <div style={{position: 'relative', marginTop:'74px'}}>
                        <div 
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                            className='px-3'
                        >
                            <h4 className='mb-3' style={{fontWeight: '700'}}>Popular Groups</h4>
                            <Sort/>
                        </div>
                    </div>
                    <div className='px-3'>
                        <div className='row px-4 pt-3'>
                            {titleArr.map((title, i) => {
                                return (
                                    <div className='col-sm-3 col-6 px-2 text-center'>
                                        <div className='group-card'>
                                            <div className='group-card-image'>
                                                <PictureFilled style={{fontSize: '100px', color: 'gray'}}/>
                                            </div>
                                            <div className='bottom-btn-section'>
                                                <div className='bottom-btn-status'>
                                                    <HeartFilled/>
                                                    72
                                                    <UserOutlined />
                                                    4
                                                </div>
                                                <div className='bottom-btn-action'>
                                                    <button>Join</button>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='pt-1' style={{fontWeight: '500', fontSize: '16px'}}>{title}</p>
                                    </div>
                                )
                            })}
                            <div className='start-group-btn-container'>
                                <div className='start-group-btn' onClick={startGroup}>
                                    <a href='/find-groups'>Find a Group</a>
                                </div>
                                <div className='start-group-btn' onClick={startGroup}>
                                    <a href='/start-a-group'>Start a New Group</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='detail-background' onClick={backClick}>
                <div className='detail-body' style={{height: '65vh'}} onClick={bodyClick}>
                    <button className='cancel-btn' onClick={hideDetail}>X</button>
                    <div>
                        <p className='detail-title'>Join Group - {modalTitle}</p>
                        <div className='detail-content'>
                            <div className='row ps-2'>
                                <div className='col-3 p-0'>
                                    <p className='detail-subtitle m-0'>Custodian:</p>
                                </div>
                                <div className='col-2 p-0 ps-3'>
                                    <div className='detail-avatar'></div>
                                </div>
                                <div className='col-7 p-0 ps-2'>
                                    <p className='m-0 rating-name1' style={{fontSize: '10px'}}>boxerboi</p>
                                    <Rate allowHalf defaultValue={3.5} />
                                </div>
                            </div>
                            <div className='row ps-2 pb-2'>
                                <div className='col-3 p-0'>
                                    <p className='detail-subtitle p-0 m-0'>Moderators:</p>
                                </div>
                                <div className='col-9 p-0 ps-4'>
                                    <p className='m-0 rating-name1' style={{fontSize: '10px', lineHeight: '10px'}}>boxerboi<br/>recumrace<br/>angelface</p>
                                </div>
                            </div>
                            <div className='row ps-2 pb-3'>
                                <div className='col-3 p-0'>
                                    <p className='detail-subtitle p-0 m-0'>Created:</p>
                                </div>
                                <div className='col-9 p-0 ps-2'>
                                    <p className='m-0 rating-name1' style={{fontSize: '10px', lineHeight: '10px'}}><strong>December</strong> 12 2015</p>
                                </div>
                            </div>
                            <div className='row ps-2 pb-3'>
                                <div className='col-3 p-0'>
                                    <p className='detail-subtitle p-0 m-0'>Members:</p>
                                </div>
                                <div className='col-9 p-0 ps-2'>
                                    <p className='m-0 rating-name1' style={{fontSize: '10px', lineHeight: '10px'}}>452 &nbsp; (350 M / 102 F)</p>
                                </div>
                            </div>
                            <div className='row ps-2 pb-3'>
                                <div className='col-2 p-0'>
                                    <p className='detail-subtitle p-0 m-0'>Posts:</p>
                                </div>
                                <div className='col-10 p-0 ps-2'>
                                    <p className='m-0 rating-name1' style={{fontSize: '10px', lineHeight: '10px'}}>1,563</p>
                                </div>
                            </div>
                            <div className='row ps-2 pb-3'>
                                <div className='col-2 p-0'>
                                    <p className='detail-subtitle p-0 m-0'>Motto:</p>
                                </div>
                                <div className='col-10 p-0 ps-2'>
                                    <p className='m-0 rating-name1' style={{fontSize: '10px', lineHeight: '12px'}}>There ain't nothing finer than a flower smooth as silk</p>
                                </div>
                            </div>
                            <div className='row ps-2 pb-3'>
                                <div className='col-3 p-0'>
                                    <p className='detail-subtitle p-0 m-0'>Description:</p>
                                </div>
                                <div className='col-9 p-0 ps-4'>
                                    <p className='m-0 rating-name1' style={{fontSize: '10px', lineHeight: '12px'}}>{modalTitle} is a group for lovers of fine flowers to share their mutual appreciation</p>
                                </div>
                            </div>
                            <div className='row ps-2 pb-3'>
                                <div className='col-8 p-0'>
                                    <p className='detail-subtitle p-0 m-0'>Accepting New Members?:</p>
                                </div>
                                <div className='col-4 p-0'>
                                    <p className='m-0 rating-name1' style={{fontSize: '10px', lineHeight: '12px'}}>Yes</p>
                                </div>
                            </div>
                            <div className='row text-center'>
                                <div className='col-12'>
                                    <button className='upload-btn px-3 ms-0' style={{marginTop: '35px', width: 'fit-content'}}>Ask to join this group</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    );
}

export default PopularGroups;