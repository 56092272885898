import React, { useState, useEffect } from "react";
import { Radio, Divider, Checkbox, Rate, message } from "antd";
import Header from "../components/header";
import GroupCard from "../components/group-card";
import Sort from "../components/sort";
import "../styles/style.css";
import { convertToGroupData, getMyGroups } from "../utils/helper";

function MyGroups({ ws }) {
  const [modalTitle, setModalTitle] = useState("");
  const [invite, setInvite] = useState(false);
  const [currentUserName, setCurrentUserName] = useState(localStorage.getItem("currentUserName"));
  const [inviteMemberName, setInviteMemberName] = useState("");
  const [inviteNote, setInviteNote] = useState("");
  const [cardData, setCardData] = useState(null);

  let flag = 0;

  const getGroups = async () => {
    try {
      const groups = await getMyGroups();
      setCardData(groups.map((item) => convertToGroupData(item)));
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getGroups();
  }, []);

  const startGroup = (e) => {
    e.target.children[0].click();
  };

  const showDetail = (e) => {
    document.getElementsByClassName("detail-background")[0].style.display = "block";
    setModalTitle(e.target.parentNode.id);
  };

  const hideDetail = (e) => {
    document.getElementsByClassName("detail-background")[0].style.display = "none";
    document.getElementsByClassName("detail-body")[0].style.height = "55vh";
    setInvite(false);
  };

  const bodyClick = () => {
    flag = 1;
  };

  const backClick = () => {
    if (flag === 0) {
      hideDetail();
    }
    flag = 0;
  };

  const inviteSomeone = () => {
    document.getElementsByClassName("detail-body")[0].style.height = "45vh";
    setInvite(true);
  };

  const onInviteMemberNameChange = (e) => {
    setInviteMemberName(e.target.value);
  };

  const onInviteNoteChange = (e) => {
    setInviteNote(e.target.value);
  };

  const sendInvite = () => {
    let bodyData = {
      c_id: inviteMemberName,
      i_note: inviteNote,
      i_status: 0,
    };
    fetch(`${process.env.REACT_APP_URL}/send_invite/${modalTitle}/${currentUserName}?manager_name=${currentUserName}&group_name=${modalTitle}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + `${localStorage.getItem("access_token")}`,
      },
      mode: "cors",
      body: JSON.stringify(bodyData),
    }).then((res) => {
      if (res) {
        let response = res.json();
        response.then(function (result) {
          if (result.Status === "Success") {
            if (result.Response === "No such a User") {
              message.error("User does not exist!");
            } else {
              message.success("Invitation was sent successfully!");
              console.log("manager");
              ws.send("A manager sends an invitation to the Member");
            }
          }
        });
      }
    });
  };

  const drop = (
    <div className="drop-content">
      <p className="menu-title mb-2 mt-1">Sort by:</p>
      <div className="ps-3">
        <Radio.Group defaultValue={1}>
          <Radio value={1}>Most Active</Radio>
          <Radio value={2}>Most Members</Radio>
          <Radio value={3}>Most Photos</Radio>
          <Radio value={4}>Newest Content</Radio>
        </Radio.Group>
      </div>
      <Divider />
      <p className="menu-title mb-2">Show Groups with:</p>
      <div className="ps-3">
        <Checkbox>photos</Checkbox>
        <Checkbox>videos</Checkbox>
        <Checkbox>status updates</Checkbox>
      </div>
    </div>
  );

  return (
    <>
      <div>
        <Header />
        <div className="container">
          <div className="mt-5 d-flex justify-content-between">
            <h4 className="px-3 mb-3" style={{ fontWeight: "700" }}>
              My Groups
            </h4>
            <Sort />
          </div>
          <div className="px-3">
            <div className="row pt-3">
              {cardData &&
                cardData.map((data, index) => {
                  return (
                    <div className="col-6 col-sm-6 col-md-4 col-lg-3 text-center" key={index}>
                      <GroupCard data={data} ownGroup={true} isLocked={true} />
                    </div>
                  );
                })}
              <div className="start-group-btn-container">
                <div className="start-group-btn" onClick={startGroup}>
                  <a href="/find-groups">Find a Group</a>
                </div>
                <div className="start-group-btn" onClick={startGroup}>
                  <a href="/start-a-group">Start a New Group</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="detail-background" onClick={backClick}>
        <div className="detail-body" onClick={bodyClick}>
          <button className="cancel-btn" onClick={hideDetail}>
            X
          </button>
          {!invite && (
            <div>
              <p className="detail-title">{modalTitle}</p>
              <div className="detail-content">
                <div className="row ps-2">
                  <div className="col-3 p-0">
                    <p className="detail-subtitle m-0">Custodian:</p>
                  </div>
                  <div className="col-2 p-0 ps-3">
                    <div className="detail-avatar"></div>
                  </div>
                  <div className="col-7 p-0 ps-2">
                    <p className="m-0 rating-name1" style={{ fontSize: "10px" }}>
                      boxerboi
                    </p>
                    <Rate allowHalf defaultValue={3.5} />
                  </div>
                </div>
                <div className="row ps-2 pb-2">
                  <div className="col-3 p-0">
                    <p className="detail-subtitle p-0 m-0">Moderators:</p>
                  </div>
                  <div className="col-9 p-0 ps-4">
                    <p className="m-0 rating-name1" style={{ fontSize: "10px", lineHeight: "10px" }}>
                      boxerboi
                      <br />
                      recumrace
                      <br />
                      angelface
                    </p>
                  </div>
                </div>
                <div className="row ps-2 pb-3">
                  <div className="col-3 p-0">
                    <p className="detail-subtitle p-0 m-0">Created:</p>
                  </div>
                  <div className="col-9 p-0 ps-2">
                    <p className="m-0 rating-name1" style={{ fontSize: "10px", lineHeight: "10px" }}>
                      <strong>December</strong> 12 2015
                    </p>
                  </div>
                </div>
                <div className="row ps-2 pb-3">
                  <div className="col-3 p-0">
                    <p className="detail-subtitle p-0 m-0">Members:</p>
                  </div>
                  <div className="col-9 p-0 ps-2">
                    <p className="m-0 rating-name1" style={{ fontSize: "10px", lineHeight: "10px" }}>
                      452 &nbsp; (350 M / 102 F)
                    </p>
                  </div>
                </div>
                <div className="row ps-2 pb-3">
                  <div className="col-2 p-0">
                    <p className="detail-subtitle p-0 m-0">Posts:</p>
                  </div>
                  <div className="col-10 p-0 ps-2">
                    <p className="m-0 rating-name1" style={{ fontSize: "10px", lineHeight: "10px" }}>
                      1,563
                    </p>
                  </div>
                </div>
                <div className="row ps-2 pb-3">
                  <div className="col-2 p-0">
                    <p className="detail-subtitle p-0 m-0">Motto:</p>
                  </div>
                  <div className="col-10 p-0 ps-2">
                    <p className="m-0 rating-name1" style={{ fontSize: "10px", lineHeight: "12px" }}>
                      There ain't nothing finer than a flower smooth as silk
                    </p>
                  </div>
                </div>
                <div className="row ps-2 pb-3">
                  <div className="col-3 p-0">
                    <p className="detail-subtitle p-0 m-0">Description:</p>
                  </div>
                  <div className="col-9 p-0 ps-4">
                    <p className="m-0 rating-name1" style={{ fontSize: "10px", lineHeight: "12px" }}>
                      {modalTitle} is a group for lovers of fine flowers to share their mutual appreciation
                    </p>
                  </div>
                </div>
                <div className="row ps-2 pb-3">
                  <div className="col-8 p-0">
                    <p className="detail-subtitle p-0 m-0">Accepting New Members?:</p>
                  </div>
                  <div className="col-4 p-0">
                    <p className="m-0 rating-name1" style={{ fontSize: "10px", lineHeight: "12px" }}>
                      Yes
                      <br />
                      <span onClick={inviteSomeone}>(invite someone)</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {invite && (
            <div>
              <p className="detail-title">{modalTitle} - Invite somwone</p>
              <div className="detail-content">
                <p className="detail-subtitle m-0 mb-3" style={{ lineHeight: "normal" }}>
                  Enter the Member Name of the person you wish to invite this group:
                </p>
                <input
                  className="input-box"
                  type="text"
                  placeholder="Enter member name..."
                  value={inviteMemberName}
                  onChange={onInviteMemberNameChange}
                />
                <p className="detail-subtitle mb-3 mt-3">Optional note to the member:</p>
                <textarea
                  className="text-box mb-3"
                  placeholder="Optional comment, eg. on why you think they'll love this group"
                  value={inviteNote}
                  onChange={onInviteNoteChange}
                />
                <button className="upload-btn" style={{ float: "right" }} onClick={sendInvite}>
                  invite
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MyGroups;
