import { Space, Checkbox, Radio, Popover } from "antd";
import React, { useState, useEffect } from "react";

function Sort(props) {
  //   const { sortData, filterData } = props;

  const sortData = [
    "Popularity",
    "Most Active",
    "Likes - Low to High",
    "Likes - High to Low",
    "Members - Low to High",
    "Members - High to Low",
    "Group Name A-Z",
    "Group Name Z-A",
  ];

  const filterData = ["Photos", "Videos", "Status updates", "Status comments"];

  const sortContainer = (
    <Radio.Group defaultValue={1}>
      <Space direction="vertical">
        {sortData.map((item, index) => (
          <Radio value={index + 1} key={index}>
            {item}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );

  const filterOptions = filterData.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const filterContainer = <Checkbox.Group options={filterOptions} style={{ display: "flex", flexDirection: "column" }} />;

  return (
    <div className="d-flex justify-content-between align-items-center gap-5 c-pointer">
      <Popover content={sortContainer} trigger="click" placement="bottomRight">
        <div className="d-flex justify-content-between align-items-center gap-3 c-pointer">
          <h5 className="menu-title m-0">Sort by:</h5>
          <span className="material-symbols-outlined">keyboard_arrow_down</span>
        </div>
      </Popover>
      <Popover content={filterContainer} trigger="click" placement="bottomRight">
        <span className="material-symbols-outlined text-dark c-pointer">tune</span>
      </Popover>
    </div>
  );
}

export default Sort;
