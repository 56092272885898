export const convertToGroupData = (group) => {
  const imgUrl = process.env.REACT_APP_URL + "/static/" + group.image_url;

  return {
    name: group.name,
    motor: group.motor,
    imgUrl: imgUrl,
    isInviteOnly: group.is_invite_only,
    isGroupOwner: group.is_group_owner,
    status: group.join_status ? group.join_status : 0
  };
};

export const getMyGroups = async () => {
  const response = await fetch(`${process.env.REACT_APP_URL}/show_member_groups`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + `${localStorage.getItem("access_token")}`,
    },
    mode: "cors",
  }).then((res) => res.json());
  if (response.Status === "Success") return response.Response;
};

export const getMyOwnGroups = async () => {
  const response = await fetch(`${process.env.REACT_APP_URL}/show_own_groups`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + `${localStorage.getItem("access_token")}`,
    },
    mode: "cors",
  }).then((res) => res.json());
  if (response.Status === "Success") return response.Response;
};

export const getOtherGroups = async () => {
  const response = await fetch(`${process.env.REACT_APP_URL}/show_groups`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + `${localStorage.getItem("access_token")}`,
    },
    mode: "cors",
  }).then((res) => res.json());

  if (response.Status === "Success") return response.Response;
};


export const sendJoinRequest = async (group_name) => {
  const response = await fetch(`${process.env.REACT_APP_URL}/send_join`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + `${localStorage.getItem("access_token")}`,
    },
    mode: "cors",
    body: JSON.stringify({
      group_name: group_name,
      user_name: JSON.parse(localStorage.getItem("currentUser")).u_name,
    }),
  }).then((res) => res.json());

  console.log("result = ", response.Response)
  if (response.Status === 'Success') return true;
  return false;
}