import { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SignIn from '../components/sign-in';
import SignUp from '../components/sign-up';
import Header from '../components/header';

function Login ( props ) {
    const [key, setKey] = useState('signin');
    
    const switchSignInTab = () => {
        setKey('signin');
    }

    const switchSignUpTab = () => {
        setKey('signup');
    }

    return (
        <div className='content'>
            <Header/>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                id="fill-tab-example"
                className="my-5"
                fill
            >
                <Tab 
                    eventKey="signin"
                    title="Login"
                >
                    <SignIn switchSignUpTab={switchSignUpTab}/>
                </Tab>
                <Tab 
                    eventKey="signup"
                    title="Sign Up"
                >
                <SignUp switchSignInTab={switchSignInTab}/>
                </Tab>
            </Tabs>
        </div>

    )
}

export default Login;