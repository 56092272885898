import React, { useEffect, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import Header from '../components/header';
import '../styles/style.css';

function ManageGroupDetail() {

    const [checkList, setCheckList] = useState([]);
    const [currentGroup, setCurrentGroup] = useState([]);

    useEffect(()=>{
        console.log("group = ", localStorage.getItem('currentGroupName'));
 
        fetch(`${process.env.REACT_APP_URL}/show_specific_group/${localStorage.getItem('currentGroupName')}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + `${localStorage.getItem('access_token')}`,
            },
            mode:'cors',
        })
        .then(res =>{
            if(res){
                let response = res.json();
                response.then(function(result){
                    console.log("specific group = ", result)
                    setCurrentGroup(result);
                })
            }
        })

        fetch(`${process.env.REACT_APP_URL}/check_join/${localStorage.getItem('currentGroupName')}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + `${localStorage.getItem('access_token')}`,
            },
            mode:'cors',
        })
        .then(res =>{
            if(res){
                let response = res.json();
                response.then(function(result){
                    console.log("result  =", result.Response)
                    if(result.Status === 'Success' && result.Response.length > 0){
                        setCheckList(result.Response);
                    }
                })
            }
        })
    }, []);

    let imgUrl = process.env.REACT_APP_URL + '/static/' + localStorage.getItem('currentGroupName');

    return (
        <div className='body-content'>
            <div className='content'>
                <Header/>
                <div className='container'>
                    <div className='px-3 d-flex justify-content-between' style={{marginTop: '70px'}}>
                        <h4 style={{fontWeight: '700'}}>Manage Group: {localStorage.getItem('currentGroupName')}</h4>
                        <SettingOutlined style={{fontSize: '20px'}}/>
                    </div>
                    <div className='row mx-0 mb-3 px-3'>
                        <div className='col-12 col-md-4 p-3 text-center'>
                            <img width='300px' height='300px' src={process.env.REACT_APP_URL + '/static/' + currentGroup.g_image_url} className='rounded'/>
                        </div>
                        <div className='row col-12 col-md-8'>
                            <div className='col-6 col-sm-3 text-end'>
                                <p className='m-0'>Members:</p>
                                <p className='m-0'>Users:</p>
                                <p className='m-0'>Posts:</p>
                                <p className='m-0'>Last Active:</p>
                                <a href='/groups'>more status...</a>
                            </div>
                            <div className='col-6 col-sm-9 text-start'>
                                <p className='m-0'>3200<a href='/manage-group-member'>(manage members)</a></p>
                                <p className='m-0'>35,000</p>
                                <p className='m-0'>45,000</p>
                                <p className='m-0'>13mis ago</p>
                            </div>
                        </div>
                    </div>
                    <div className='row px-3'>
                        <h4 className='col-6'>Join Requests({ checkList.length })</h4>
                        <div className='col-6 btn-group manage-detail-btn' role='group'>
                            <button className='btn border-dark'>Approve All</button>
                            <button className='btn border-dark'>Decline All</button>
                        </div>
                    </div>
                    <div className='px-3 py-3 d-flex flex-column gap-3'>
                    {
                        checkList.map(( item )=> {
                            return (
                                    <div className='row d-flex align-items-center'>
                                        <div className='col-2'>
                                            <img width='100%' src='User.jpg'/>
                                        </div>
                                        <div className='col-5 d-flex flex-column'>
                                            <h4> { item.user_name } </h4>
                                            <p className='m-0'>Joined Groups 1month ago</p>
                                            <p className='m-0'>Member of 2 Groups</p>
                                            <p className='m-0'>(display badges)</p>
                                        </div>
                                        <div className='col-5 d-flex gap-2 btn-group'>
                                            <button className='btn border-dark rounded'>Approve</button>
                                            <button className='btn border-dark rounded'>Decline</button>
                                        </div>
                                    </div>
                                )
                            })
                    }
                    </div>
                    {/* <div className='row mt-3 px-3'>
                        <a className='link-btn mb-3' href='join-request'>View Join Requests ( 5 new )</a>
                        <a className='link-btn mb-3' href='#'>Settings Review</a>
                        <a className='link-btn mb-3' href='manage-group-member'>Manage Group Members</a>
                        <a className='link-btn mb-3' href='#'>View Group</a>
                    </div> */}
                </div>
            </div>
        </div>    
    );
}

export default ManageGroupDetail;