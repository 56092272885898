import React, { useEffect, useState } from "react";
import { Button, message, Modal } from "antd";
import { HeartFilled, UserOutlined } from "@ant-design/icons";
import Header from "../components/header";
import "../styles/style.css";
import { convertToGroupData, getOtherGroups, sendJoinRequest } from "../utils/helper";
import GroupCard from "../components/group-card";
import OtherGroupCard from "../components/GroupCards/other-group-card";

function Groups(change) {
  const [visible, setVisible] = useState(false);
  const [invitationList, setInvitationList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [cardData, setCardData] = useState(null);

  const getGroups = async () => {
    try {
      const groups = await getOtherGroups();
      console.log("groups = ", groups)
      const filtered = groups.filter(group => group.group_status === true);
      setCardData(filtered.map((item) => convertToGroupData(item)));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  const joinRequest = async (e) => {
    const res = await sendJoinRequest(e.target.name);
    if(res === true) {
      getGroups()
      message.success("Your join request was sent successfully!");
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        <h4 className="pt-3 px-3" style={{ fontWeight: "700", marginTop: "50px" }}>
          My Groups
        </h4>
        <div className="px-3">
          <p className="py-1 heading-1">
            You are not a member of any groups! You can <a>find groups of interest</a> or <a>start your own group</a>.
          </p>
          <p className="py-1 heading-1">Here are some suggested groups for you to join.</p>
          <div className="row pt-3">
            {cardData &&
              cardData.map((data, index) => {
                return (
                  <div className="col-6 col-sm-6 col-md-4 col-lg-3 text-center" key={index}>
                    <OtherGroupCard data={data} joinRequest={joinRequest}/>
                  </div>
                );
              })}
            <div className="col-sm-12 col-12 d-flex justify-content-center">
              <div className="start-group-btn">
                <a href="/start-a-group">Start a New Group</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </> 
  );
}

export default Groups;
