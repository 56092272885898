import { HeartFilled, UserOutlined } from "@ant-design/icons";
import { GroupStatus } from "../../constants/application";

function OtherGroupCard(props) {
  const { data, joinRequest } = props;
  const { imgUrl, name, isInviteOnly, status } = data;

  return (
    <>
      <div className="card border-0">
        <div className="card-image">
          <img className="card-img-top" src={imgUrl} alt="" />
          {!!isInviteOnly && (
            <div className="image-overlay text-end">
              <span className="material-symbols-outlined">lock</span>
            </div>
          )}
        </div>

        <div className="bottom-btn-section">
          <div className="bottom-btn-status">
            <HeartFilled />
            72
            <UserOutlined />4
          </div>
          <div className="bottom-btn-action">
            {status === 1 ? 
              <button>{GroupStatus.Pending}</button> 
              : 
              <button
                name={name}
                onClick={joinRequest}
              >
                {GroupStatus.Join}
              </button>
            }
          </div>
        </div>
      </div>
      <p className="pt-1" style={{ fontWeight: "500", fontSize: "16px" }}>
        {name}
      </p>
    </>
  );
}

export default OtherGroupCard;
