import AllomboIcon from '../images/Allombo.png';
import { encode as base64encode } from "base64-arraybuffer";
import { message } from 'antd';

// import { Buffer } from 'buffer';
// import base64url from 'base64url';
// import { sha256 } from 'crypto-hash';
// import crypto from 'crypto';
// import randomstring from "randomstring";
// import crypto from 'crypto';
// Buffer.from('anything','base64');

// import cryptoRandomString from 'crypto-random-string';

function SininWithAllombo ( props ) {
    // GENERATING CODE VERIFIER
    function dec2hex(dec) {
        return ("0" + dec.toString(16)).substr(-2);
    }
    
    function generateCodeVerifier() {
        var array = new Uint32Array(43 / 2);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec2hex).join("");
    }

    // Code Challenge from code verifier
    // function sha256(plain) {
    //     // returns promise ArrayBuffer
    //     const encoder = new TextEncoder();
    //     const data = encoder.encode(plain);
    //     return window.crypto.subtle.digest("SHA-256", data);
    //     // return crypto.subtle.digest("SHA-256", data);

    // }
    
    // function base64urlencode(a) {
    //     var str = "";
    //     var bytes = new Uint8Array(a);
    //     var len = bytes.byteLength;
    //     for (var i = 0; i < len; i++) {
    //         str += String.fromCharCode(bytes[i]);
    //     }
    //     return btoa(str)
    //         .replace(/\+/g, "-")
    //         .replace(/\//g, "_")
    //         .replace(/=/g, "");
    // }
    
    // const base64Digest = crypto.createHash("sha256")
    //                             .update(code_verifier)
    //                             .digest("base64");

    async function generateCodeChallengeFromVerifier(v) {
        // var hashed = await sha256(v);
        // var base64encoded = base64urlencode(hashed);
        // return base64encoded;

        // var verifyBytes = base64urlencode(v);
        // var hashedBytes = await sha256(v);
        // var transformedCodeVerifier = base64urlencode(hashedBytes);

        // // console.log('code challenge --> ', transformedCodeVerifier);

        // return transformedCodeVerifier;

        const encoder = new TextEncoder();
        const data = encoder.encode(v);
        const digest = await window.crypto.subtle.digest("SHA-256", data);
        const base64Digest = base64encode(digest);
        // you can extract this replacing code to a function
        return base64Digest
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
    }

    const _siteUrl = process.env.REACT_APP_Site_URL;
    const _clientId = process.env.REACT_APP_API_Key;
    const _redirectURI = process.env.REACT_APP_Redirect_URI;    
    const _state = 'abcdefghijklmnopqrstuvwxyz1234567890abcdefghijklmnopqrstuvwxyz1234567890!@';
    const _scope = 'profile';

    async function attachEvent(popUpWindow, code_verifier){
        // console.log('attacthEventWindow');
        await window.addEventListener(
            'message',
            function(event) {  
                if (popUpWindow.location.href !== undefined) {
                    if (popUpWindow.location.href.includes(_redirectURI) > 0 ) {
                        const urlParams = new URLSearchParams(popUpWindow.location.search);
                        const _code = urlParams.get('code');
                        const state = urlParams.get('state');

                        // console.log('state ----> ', state);
                        // console.log('code ----> ', _code);

    
                        popUpWindow.close();
                        get_allombo_token(_code, code_verifier);                        
    
                        return _code;
                    } else {
                        // console.log('no stat and code');
                    }
                } else{
                    // console.log('location undefined');
                }

            }, 
            false
        );
    }
    async function startAuth(_codeChallenge, code_verifier) {
        var popWidth = 600;
        var popHeight = 600;
        var popLeft = (window.screen.width - popWidth) / 2;
        var popTop = (window.screen.height - popHeight) / 4;
        var windowOptions = 'popup,left=' + popLeft + ',top=' + popTop + ',width=' + popWidth + ',height=' + popHeight;
        var url = _siteUrl + '/auth/?response_type=code&client_id=' + _clientId + '&redirect_uri=' + _redirectURI + '&state=' + _state + '&scope=' + _scope + '&code_challenge=' + _codeChallenge + '&code_challenge_method=S256';
        const _authWindow = window.open(url, '', windowOptions);

        const code = await attachEvent(_authWindow, code_verifier);

        return code;
    };
    
    async function get_allombo_token(code, code_verifier){
        let bodyData = {
            'code': code,
            'code_verifier': code_verifier
        };

        await fetch(`${process.env.REACT_APP_URL}/allombo_token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode:'cors',
            body: JSON.stringify(bodyData)
        })
        .then(res =>{
            if(res){
                let response = res.json();
                response.then(function(result){
                    if(result.Status === 'Success'){
                        localStorage.setItem('access_token', result.access_token);
                        localStorage.setItem('currentUser', JSON.stringify(result.user));
                        message.success('Sign in successfully!');
                        props.navigate("/groups");
                    }
                }).catch(err =>{
                    message.error(err);
                });
            }
        })
        .catch(err => {
            message.error(err);
        });
    }

    const onSubmitWithAllombo = async () => {
        if (localStorage.getItem('code_verifier') == undefined) {
            localStorage.setItem('code_verifier', generateCodeVerifier());
            // localStorage.setItem('code_verifier', randomstring.generate(128));
        }
        const code_verifier = localStorage.getItem('code_verifier');
        // const code_verifier = generateCodeVerifier();
        const code_challenge = await generateCodeChallengeFromVerifier(code_verifier);
        // console.log('code_challenge ===> ', code_challenge);
        // console.log('code_verifier ===> ', code_verifier);
        const code = await startAuth(code_challenge, code_verifier);     
    }

    return (
        <button 
            id='loginwithz'
            className='sign-in-allombo'
            onClick={onSubmitWithAllombo}
        >
            <img src={AllomboIcon} width={24} className='mx-2'/>
            Sign in with Allombo
        </button>
    )
}

export default SininWithAllombo;