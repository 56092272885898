import React, {useState} from 'react';
import { Popover, Radio, Checkbox, Divider, Table } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Header from '../components/header';
import '../styles/style.css';
import Sort from '../components/sort';

const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Photos',
      dataIndex: 'photos',
    },
    {
      title: 'Vids',
      dataIndex: 'vids',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
    },
    {
      title: 'Last Post',
      dataIndex: 'lastPost',
    },
    {
      title: 'Gag',
      dataIndex: 'gag',
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
    },
    {
      title: 'PM',
      dataIndex: 'pm',
    },
]

const disData = [
    {
        key: '1',
        name: 'Harry',
        photos: 42,
        vids: 12,
        status: 14,
        comment: 54,
        lastPost: '12 Jun',
        gag: 'G',
        delete: 'D',
        pm: 'PM'
    },
    {
        key: '2',
        name: 'Jane',
        photos: 0,
        vids: 1,
        status: 677,
        comment: 3,
        lastPost: '13 Jun',
        gag: 'G',
        delete: 'D',
        pm: 'PM'
    },
]

function ManageGroupMember(props) {

    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(disData)

    fetch(`${process.env.REACT_APP_URL}/show_member_groups/1/free`, {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + `${localStorage.getItem('access_token')}`,
      },
      mode:'cors',
    })
    .then(res =>{
        if(res){
            let response = res.json();
            response.then(function(result){
                console.log("specific group = ", result)
                
            })
        }
    })


    const drop = (
        <div className='drop-content'>
            <p className='menu-title mb-2 mt-1'>Sort by:</p>
            <div className='ps-3'>
                <Radio.Group defaultValue={1}>
                    <Radio value={1}>Most Photos</Radio>
                    <Radio value={2}>Most Videos</Radio>
                    <Radio value={3}>Most Status</Radio>
                    <Radio value={4}>Newest Content</Radio>
                </Radio.Group>
            </div>
            <Divider />
            <p className='menu-title mb-2'>Show Members who posted:</p>
            <div className='ps-3'>
                <Checkbox>photos</Checkbox>
                <Checkbox>videos</Checkbox>
                <Checkbox>status updates</Checkbox>
                <Checkbox>comments</Checkbox>
            </div>
        </div>
    );

    const group = [1, 2, 3];

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
        <div className='body-content'>
            <div className='content'>
                <Header/>
                <div className='container'>
                  <div className='px-3 d-flex justify-content-between' style={{marginTop: '70px'}}>
                        <h4 style={{fontWeight: '700'}}>Manage Group: {localStorage.getItem('currentGroupName')}</h4>
                        <SettingOutlined style={{fontSize: '20px'}}/>
                  </div>
                  <div className='px-3 py-3'>
                    <button type='button' className='btn border-dark' onClick={handleShow}>Invite a Member</button>
                  </div>
                  
                  <div className='px-3 d-flex justify-content-between'>
                    <h4>Members</h4>
                    <Sort/>
                  </div>
                  <div className='px-3'>
                    <input className='form-control' placeholder='Member Search..'/>
                  </div>
                  <div className='px-3 py-3'>
                    { group.length > 0 ? group.map((item)=> {
                      return (
                        <div className='row d-flex align-items-center'>
                            <div className='col-2'>
                                <img width='100%' src='User.jpg'/>
                            </div>
                            <div className='col-5 d-flex flex-column'>
                                {/* <h4> { item.user_name } </h4> */}
                                <p className='m-0'>Joined Groups 1month ago</p>
                                <p className='m-0'>Member of 2 Groups</p>
                                <p className='m-0'>(display badges)</p>
                            </div>
                            <div className='col-5 d-flex gap-2 btn-group'>
                                <button className='btn border-dark rounded'>Block</button>
                            </div>
                            <Divider style={{width: '100%'}}/>
                        </div>
                      )}): 
                      <div className='py-3 text-center'>
                        <p>You have no members in your Group.</p>
                        <a href='#'>Find members to invite here...</a>
                      </div>
                    }
                  </div>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Invite a Member</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <div className="form-group">
                          <label for="groupName"><h5>To Group:</h5></label>
                          <p id='groupName'>{localStorage.getItem('currentGroupName')}</p>
                        </div>
                        <div className="form-group">
                          <label for="memberSearch"><h5>Who would you like to invite? :</h5></label>
                          <input className='form-control mb-3' placeholder='Member Search..' id='memberSearch'/>
                        </div>
                        <div className="form-group">
                          <label for="invitationMessage"><h5>Invitation Message:</h5></label>
                          <textarea className="form-control" id="invitationMessage" rows="3"></textarea>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button variant="success" onClick={handleClose}>
                        Send Invite
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
            </div>
        </div>
    );
}

export default ManageGroupMember;