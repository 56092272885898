import React, {useState, useEffect} from 'react';
import { Select } from 'antd';
import Header from '../components/header';
import '../styles/style.css';

const { Option } = Select;

const placeholder = 'Type your status or thought here, if a photo or video, this is where you put the title.';

function PostContent() {
    
    const [groupName, setGroupName] = useState('a');
    const [userName, setUserName] = useState(localStorage.getItem('currentUserName'));
    const [imgUrl, setImgUrl] = useState('');
    const [contentTitle, setContentTitle] = useState('');
    const [files, setFiles] = useState(''); 
    const [groupList, setGroupList] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/show_own_group`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + `${localStorage.getItem('access_token')}`,
            },
            mode:'cors',
        })
        .then(res =>{
            if(res){
                let response = res.json();
                response.then(function(result){
                    if(result.Status === 'Success'){
                        setGroupList(result.Response);
                    }
                })
            }
        })
    }, []);

    const onUpload = () => {
        document.getElementById('imgInp').click();
    }

    const selectFile = (e) => {
        const [file] = document.getElementById('imgInp').files;
        setFiles(document.getElementById('imgInp').files)
        setImgUrl(URL.createObjectURL(file));
    }

    const onContentTileChange = (e) => {
        setContentTitle(e.target.value);
    }

    const onGroupNameChange = (value) => {
        setGroupName(value);
    }

    const onSubmit = () => {
        let formData = new FormData();
        formData.append('upload_stream', files[0]);

        fetch(`${process.env.REACT_APP_URL}/upload_stream`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + `${localStorage.getItem('access_token')}`,
            },
            mode:'cors',
            body:formData
        })
        .then(res =>{
            if(res){
                let response = res.json();
                response.then(function(result){
                    if(result.Status === 'Success'){
                        let bodyData = {
                            s_content_type: 0,
                            s_title: contentTitle,
                            s_media_path: result.Response
                        };
                        fetch(`${process.env.REACT_APP_URL}/start_stream/${groupName}/${userName}?group_name=${groupName}&user_name=${userName}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + `${localStorage.getItem('access_token')}`,
                            },
                            mode:'cors',
                            body:JSON.stringify(bodyData)
                        })
                        .then(res =>{
                            if(res){
                                let response = res.json();
                                response.then(function(result){
                                    // if(result.Status === 'Success'){
                                    //     
                                    // }
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    return (
        <div className='body-content'>
            <div className='content'>
                <Header/>
                <div className='container'>
                    <h4 className='pt-4 ps-3 mb-3' style={{fontWeight: '700', width: '100vw', marginTop:'50px'}}>Post Content</h4>
                    <div className='row px-3 mb-4'>
                        <div className='col-5 pe-0'>
                            <p className=' mb-0 content-1' style={{paddingTop: '3px'}}>Post to Group:</p>
                        </div>
                        <div className='col-6 pt-1 ps-0'>
                            <Select
                                className='custodian-select'
                                placeholder='group name'
                                style={{width: '100%'}}
                                onChange={onGroupNameChange}
                            >
                                {groupList.map(group => {
                                    return(
                                        <Option value={group.name}>{group.name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    <p className='mt-1 mb-0 ps-4 content-1'>Status Update / Thouht or Title:</p>
                    <textarea className='post-content mx-4' placeholder={placeholder} value={contentTitle} onChange={onContentTileChange}/>
                    <p className=' mt-4 mb-0 ps-4 content-1'>Include a photo or video in this post:</p>
                    <div className='mt-3 mx-0 px-3 text-center'>
                        <button className='find-btn px-3 mb-3 mx-0' style={{width: '85%', height: '40px'}} onClick={onUpload} >Click to browse for photo / video</button>
                        <img className='post-img' src={imgUrl} alt=''/>
                        <button className='find-btn px-3 my-3 mx-0' style={{width: '85%', height: '40px'}} onClick={onSubmit}>Submit Post</button>
                    </div>
                    <input accept="image/*" type='file' id="imgInp" style={{display: 'none'}} onChange={selectFile} />
                </div>
            </div>
        </div>    
    );
}

export default PostContent;