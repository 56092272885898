import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, message } from 'antd';
import SigninWithAllombo from '../pages/sign-in-with-allombo';
import '../styles/style.css';

function SignUp(props) {

    const { switchSignInTab } = props;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(''); 

    const confirm = () => {
        Modal.warning({
          title: 'Reset Password!',
          content: 'Please check your email to reset your password.',
          okText: 'Confirm',
          width: '400px'
        });
    };

    const onSubmit = () => {
        const emailMatch = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
        // const phoneMatch = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(document.getElementById('phone').value)
        if(!emailMatch) {
            message.error('Invalid Email!');
        }else {
            fetch(`${process.env.REACT_APP_URL}/sign_up`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + `${localStorage.getItem('access_token')}`,
                },
                mode:'cors',
                body:JSON.stringify({u_name: name, u_password: password, u_email: email})
            })
            .then(res =>{
                if(res){
                    let response = res.json();
                    response.then(function(result){
                        if(result.Status === 'Success'){
                            if(result.Response === 1){
                                localStorage.setItem('currentUserName',name)
                                message.success('Registered successfully!');    
                                props.navigate("/signin");
                            }else {
                                message.error('Account already exist!');    
                            }
                        }
                    })
                }
            })
        }
    }

    const onNameChange = (e) => {
        setName(e.target.value);
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    }

    return (
        <div className='body-content'>
            <div className='content'>
                <div className='sign-in-container'>
                    <SigninWithAllombo/>
                    <h4 style={{marginBottom: 'unset'}}> OR </h4>
                    <input 
                        className='sign-in-text'
                        type='text'
                        value={name}
                        placeholder='UserName'
                        onChange={onNameChange}
                    />
                    <input 
                        className='sign-in-text'
                        type='email'
                        value={email}
                        placeholder='Valid email address - we do not spam'
                        onChange={onEmailChange}
                    />
                    <input
                        className='sign-in-text mb-2'
                        type='password'
                        value={password}
                        placeholder='Password'
                        onChange={onPasswordChange}
                    />
                    <p>
                        By joining the site, you're agreeing to <b>the terms and conditions of use</b>.
                    </p>
                    <button className='sign-in-btn' onClick={onSubmit} >Sign Up</button>
                </div>
                <div className='sign-in-footer'>
                    <p className='text-center'>Not a member? &nbsp;
                        <a style={{color: 'black', fontWeight: 'bolder'}} onClick={switchSignInTab}>Login</a>
                    </p>
                    <p className='text-center mb-3' id='forgot' onClick={confirm}><b>Forgot your password?</b></p>
                </div>
            </div>
        </div>    
    );
    
}

function NavigateMain(props) {
    let navigate = useNavigate();
    return <SignUp {...props} navigate={navigate} />
}

export default NavigateMain;