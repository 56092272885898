import React, {useEffect} from 'react';
import { Select, Checkbox, Switch, Collapse } from 'antd';
import Header from '../components/header';
import '../styles/style.css';

const { Option } = Select;
const { Panel } = Collapse;

function FindGroup() {

    const typeOptions = [
        { label: 'Premium', value: 'Premium'},
        { label: 'Free', value: 'Free'},
        { label: 'PSP', value: 'PSP'},
    ];

    const handleChangeType = (checkedValues) => {
        console.log('checked = ', checkedValues);
      };
      
    useEffect(() => {
        window.addEventListener("load", () => {
            onResize();
        });
        window.onresize = onResize;
    }, []);

    const onResize = () => {
        let elements = document.getElementsByClassName('content-1');
        for(let i = 0; i < elements.length; i++) {
            let spanWidth = 0;
            let otherElements = [];
            for(let j = 0; j < elements[i].children.length; j++) {
                if(elements[i].children[j].tagName === 'SPAN'){
                    spanWidth += elements[i].children[j].clientWidth;
                }else {
                    otherElements.push(elements[i].children[j]);
                }
            }
            if(otherElements.length > 1) {
                otherElements.forEach(element => {
                    element.style.width = (elements[i].clientWidth - spanWidth)*0.95/otherElements.length + 'px';
                })
            }else if(otherElements.length === 1) {
                otherElements[0].style.width = (elements[i].clientWidth - spanWidth)*0.98 + 'px';
            }
            
        }
    }

    return (
        <div className='body-content'>
            <div className='content'>
                <Header title='header' />
                <div className='container'>
                    <h4 className='pt-4 ps-3 mb-3' style={{fontWeight: '700', width: '100vw', marginTop:'50px'}}>Find a Group</h4>
                    <div className='mb-3 ps-3 pe-3'>
                        <p className='mt-2 mb-0 content-1'>Group name contains:</p>
                        <input className='input-box' type='text' placeholder='Group Name'/>
                    </div>
                    <div className='mb-3 ps-3 pe-3'>
                        <p className='mt-2 mb-0 content-1'>Description contains:</p>
                        <input className='input-box' type='text' placeholder='Description'/>
                    </div>
                    <div className='mb-3 ps-3 pe-3'>
                        <p className='mt-2 mb-0 content-1'>Group Owner:</p>
                        <input className='input-box' type='text' placeholder='User Name Search ...'/>
                    </div>
                    <div className='mb-3 ps-3 pe-3'>
                        <p className='mt-2 mb-0 content-1'>Group has:</p>
                        <Select
                            placeholder='Any number of members'
                            style={{
                                width: '100%',
                            }}
                        >
                            <Option value='10'>More than 10 members</Option>
                            <Option value='50'>More than 50 members</Option>
                            <Option value='100'>More than 100 members</Option>
                            <Option value='150'>More than 150 members</Option>
                            <Option value='200'>More than 200 members</Option>
                        </Select>
                    </div>
                    <div className='mb-3 ps-3 pe-3'>
                        <p className='mt-2 mb-0 content-1'>Allows these type of members:</p>
                        <div style={{display: 'flex', gap: '20px'}}>
                            <Checkbox.Group
                                options={typeOptions}
                                onChange={handleChangeType}
                            />
                        </div>
                    </div>
                    <div className='mb-3 ps-3 pe-3' style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p className='mt-2 mb-0 content-1'>Accepting new members:</p>
                        <Switch defaultChecked />
                    </div>
                    <div className='mb-3 ps-3 pe-3' style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p className='mt-2 mb-0 content-1'>Invite only group:</p>
                        <Switch defaultChecked />
                    </div>
                    <div className='mb-3 ps-3 pe-3' >
                        <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition="right">
                            <Panel header="Advanced Search">
                                <p> This is Advanced Search. </p>
                            </Panel>
                        </Collapse>
                    </div>
                    <div className='mb-3 mt-5 ps-3 pe-3' style={{display:'flex', justifyContent: 'center'}}>
                        <button className='default-btn'>Search</button>
                    </div>
                </div>
            </div>
        </div>    
    );
}

export default FindGroup;