import React, { useState, useEffect } from "react";
import Header from "../components/header";
import GroupCard from "../components/group-card";
import { convertToGroupData, getMyOwnGroups } from "../utils/helper";
import "../styles/style.css";
import Sort from "../components/sort";

function ManageGroups() {
  const [groupList, setGroupList] = useState([]);
  const [cardData, setCardData] = useState(null);

  const getGroups = async () => {
    try {
      const groups = await getMyOwnGroups();
      setCardData(groups.map((item) => convertToGroupData(item)));
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getGroups();
  }, []);

  const startGroup = (e) => {
    e.target.children[0].click();
  };

  const showDetail = (e) => {
    localStorage.setItem("currentGroupName", e.target.id);
    document.getElementById("redirect").click();
  };

  return (
    <div className="body-content">
      <div className="content">
        <Header />
        <div className="container">
          <div style={{ position: "relative", marginTop: "74px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              className="px-3"
            >
              <h4 className="mb-3" style={{ fontWeight: "700" }}>
                Manage My Groups
              </h4>
              <Sort />
            </div>
          </div>
          <div className="px-3">
            <div className="row pt-3">
              {cardData &&
                cardData.map((data, index) => {
                  return (
                    <div className="col-6 col-sm-6 col-md-4 col-lg-3 text-center" key={index}>
                      <GroupCard data={data} ownGroup={true} isLocked={true} />
                    </div>
                  );
                })}
              <div className="start-group-btn-container">
                <div className="start-group-btn" onClick={startGroup}>
                  <a href="/start-a-group">Start a New Group</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageGroups;
