import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import menuIcon from "../images/menu-icon.svg";
import "../styles/style.css";

function Header(props) {
  const navigate = useNavigate();

  const currentUser = localStorage.getItem("currentUser");
  const [sideMenu, setSideMenu] = useState(["my stream", "my groups", "popular groups", "find groups", "start a group", "manage groups", "sign out"]);

  return (
    <div
      className={`p-3 d-flex ${currentUser ? "justify-content-between" : "justify-content-center"}`}
      style={{
        borderBottom: "black solid 2px",
      }}
    >
      {currentUser && (
        <button className="openbtn" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
          <img src={menuIcon} alt="" style={{ width: "24px" }} />
        </button>
      )}
      <Link to="/groups">
        <h4
          style={{
            fontWeight: 800,
            margin: 0,
          }}
        >
          GROUPS
        </h4>
      </Link>
      {currentUser && (
        <div className="d-flex gap-2">
          <Link to='/post-content' style={{ color: 'black'}}>
            <span className="material-symbols-outlined">add_box</span>
          </Link>
          <Link to='/find-groups' style={{ color: 'black'}}>
            <span className="material-symbols-outlined">search</span>
          </Link>
          <Link to='/find-groups' style={{ color: 'black'}}>
            <span className="material-symbols-outlined">notifications</span>
          </Link>
        </div>
      )}

      <div className="offcanvas offcanvas-start text-bg-dark" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <a className="link" href="/groups">
            <h4 className="offcanvas-title">Groups</h4>
          </a>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          {sideMenu.map((item, i) => {
            let url = "/" + item.split(" ").join("-");
            return (
              <div id={i} key={i}>
                <a className="link" href={url}>
                  <h5>{item}</h5>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Header;
